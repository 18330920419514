import { vipCards } from "constants";
import { useEffect, useState } from "react";
import { cardMetadata } from "services/cardMetadata";
import { useAuth } from "./useAuth";
import { useCardContractRead } from "./useCardContractRead";
import { useCardsContract } from "./useCardsContract";

export function useValidateVipCards() {
  const { walletId } = useAuth();
  const cardsContract = useCardsContract();
  const [cardVipState, setCardVipState] = useState({
    cardsWithState: vipCards,
    ownsAll: false,
    loading: true,
  });

  const { cardsWithState: ownedVipCards, ownsAll, loading } = cardVipState;
  const { balanceOfData: balanceOfCards } = useCardContractRead(
    "balanceOf",
    [walletId],
    true
  );

  useEffect(() => {
    (async () => {
      let ownsAll = true;
      const amountOfOwnedCards = balanceOfCards?.toNumber();
      if (!amountOfOwnedCards) return;
      const vipOwnedCards = [...vipCards];
      await Promise.all(
        Array(amountOfOwnedCards)
          .fill()
          .map(async (x, i) => {
            const tokenId = await cardsContract.tokenOfOwnerByIndex(
              walletId,
              i
            );
            const cardId = tokenId.toNumber();
            const cardUri = await cardsContract.tokenURI(cardId);
            let cardData = await cardMetadata(cardUri);
            const cardNumber = cardData.number - 1;
            if (vipOwnedCards[cardNumber]) {
              vipOwnedCards[cardNumber] = {
                ...vipOwnedCards[cardNumber],
                owned: true,
              };
            }
          })
      );
      if (
        vipOwnedCards.filter((card) => !!card.owned).length <
        vipOwnedCards.length
      )
        ownsAll = false;
      setCardVipState({
        cardsWithState: vipOwnedCards,
        ownsAll:
          process.env.REACT_APP_REACT_ENV !== "prod" &&
          vipOwnedCards.filter((card) => !!card.owned).length > 0
            ? true
            : ownsAll,
        loading: false,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceOfCards, walletId]);

  return {
    ownedVipCards,
    ownsAll,
    loading,
  };
}

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useContractWrite } from "wagmi";
import { useEtherPacksContract, useAuth } from "hooks";
import area54PacksAbi from "abi/Area54Packs.json";
import openingPackMobileSvg from "assets/opening-pack-mobile.svg";
import openingPackDesktopSvg from "assets/opening-pack-desktop.svg";
import packGif from "assets/card-pop-up.gif";
import styles from "./OpenPack.module.scss";

const INCREASE_RATE = 1.2;
const PACKS_CONTRACT_ADDRESS = process.env.REACT_APP_PACKS_CONTRACT_ADDRESS;
export const OpenPack = ({ pack }) => {
  const { walletId } = useAuth();
  const [disable, setDisable] = useState(false);
  const ethPackContract = useEtherPacksContract();
  const { isError, isLoading, writeAsync } = useContractWrite({
    mode: "recklesslyUnprepared",
    address: PACKS_CONTRACT_ADDRESS,
    abi: area54PacksAbi,
    functionName: "openPack",
  });

  useEffect(() => {
    if (isError) setDisable(false);
  }, [isError]);

  async function handleOpen() {
    try {
      setDisable(true);

      const estimatedGas = await ethPackContract.estimateGas.openPack(pack, {
        from: walletId,
      });

      const result = await writeAsync({
        recklesslySetUnpreparedArgs: [pack],
        recklesslySetUnpreparedOverrides: {
          gasLimit: parseInt(estimatedGas.toNumber() * INCREASE_RATE),
        },
      });

      if (result && result.wait) await result.wait();
      if (result.error) {
        throw result.error;
      }
    } catch (error) {
      setDisable(false);
      console.error(error);
    }
  }

  return (
    <>
      <button className="btn" disabled={disable} onClick={handleOpen}>
        {disable ? "Opening Pack" : "Open pack"}
      </button>
      {isLoading && (
        <div className={styles.overlay}>
          <img
            src={openingPackMobileSvg}
            alt="Opening pack"
            className={styles.openingPackMobile}
          />
          <img
            src={openingPackDesktopSvg}
            alt="Opening pack"
            className={styles.openingPackDesktop}
          />
          <p>Please wait for the transaction to complete.</p>
          <img src={packGif} alt="Pack" className={styles.pack} />
        </div>
      )}
    </>
  );
};

OpenPack.propTypes = {
  pack: PropTypes.number,
};

export default OpenPack;

import { stages } from "constants";
import { getStage } from "utils";
import styles from "./Marquee.module.scss";

const stage = getStage();

const MARQUEE_TEXT = {
  default:
    "VIP MINT: NOV 9 & 10 • PUBLIC MINT: NOV 11 • PACK REVEAL: NOV 11 • VIP MINT: NOV 9 & 10 • PUBLIC MINT: NOV 11 • PACK REVEAL: NOV 11 • VIP MINT: NOV 9 & 10 • PUBLIC MINT: NOV 11 • PACK REVEAL: NOV 11 •",
  [stages.stage3]:
    "VIP SOLD OUT! PUBLIC MINT & PACK REVEAL NOV 11 @ 2pm ET • VIP SOLD OUT! PUBLIC MINT & PACK REVEAL NOV 11 @ 2pm ET • VIP SOLD OUT! PUBLIC MINT & PACK REVEAL NOV 11 @ 2pm ET • VIP SOLD OUT! PUBLIC MINT & PACK REVEAL NOV 11 @ 2pm ET • VIP SOLD OUT! PUBLIC MINT & PACK REVEAL NOV 11 @ 2pm ET • ",
  [stages.stage4]:
    "PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW •",
  [stages.stage5]:
    "PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW • PUBLIC MINT LIVE NOW •",
};

export const Marquee = () => {
  return (
    <div className={styles.marqueeContainer}>
      <p className={`${styles.marquee} ${styles.initialMarquee}`}>
        <span>{MARQUEE_TEXT[stage] || MARQUEE_TEXT.default}</span>
      </p>
      <p className={styles.marquee}>
        <span>{MARQUEE_TEXT[stage] || MARQUEE_TEXT.default}</span>
      </p>
      <p className={`${styles.marquee} ${styles.marquee2}`}>
        <span>{MARQUEE_TEXT[stage] || MARQUEE_TEXT.default}</span>
      </p>
    </div>
  );
};

export default Marquee;

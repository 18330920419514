export function getStage() {
  let stage = process.env.REACT_APP_STAGE;
  const params = new URLSearchParams(window.location.search);
  if (process.env.REACT_APP_REACT_ENV !== "prod" && params.get("stage")) {
    stage = params.get("stage");
  }
  return stage;
}

export default getStage;

import { useContractRead } from "wagmi";
import area54CardsAbi from "abi/Area54Cards.json";

const CARDS_CONTRACT_ADDRESS = process.env.REACT_APP_CARDS_CONTRACT_ADDRESS;

export function useCardContractRead(functionName, args = [], watch = false) {
  const { data, isError, isLoading, isSuccess } = useContractRead({
    address: CARDS_CONTRACT_ADDRESS,
    abi: area54CardsAbi,
    functionName,
    args,
    watch,
  });

  return {
    [`${functionName}Data`]: data,
    [`${functionName}Error`]: isError,
    [`${functionName}Loading`]: isLoading,
    [`${functionName}Success`]: isSuccess,
  };
}

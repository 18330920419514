import area54PacksAbi from "abi/Area54Packs.json";
import { ethers } from "ethers";
import { useProvider } from "wagmi";

const PACKS_CONTRACT_ADDRESS = process.env.REACT_APP_PACKS_CONTRACT_ADDRESS;

export function useEtherPacksContract() {
  const provider = useProvider();
  const contract = new ethers.Contract(
    PACKS_CONTRACT_ADDRESS,
    area54PacksAbi,
    provider
  );
  return contract;
}

import { WALLET_API } from "constants";
import { fetchApi } from "utils";

export async function area54MerkleProof(walletAddress) {
  const data = await fetchApi(`${WALLET_API}/merkle-proof`, "post", {
    project: "AREA54",
    walletAddress,
  });
  return { merkleProof: data?.merkleProof ?? [] };
}

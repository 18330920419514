import { useEffect, useState, useMemo } from "react";
import { useAuth, usePackContractRead, usePacksContractWrite } from "hooks";
import { usePackContract } from "hooks/usePackContract";
import { Marquee, Pack, SocialHeader, ConnectButton } from "components";
import { cryptoClubHolder } from "services/cryptoClubHolder";
import { area54MerkleProof } from "services/merkleProof";
import MintHeaderDesktop from "assets/mint-header-desktop.png";
import MintHeaderMobile from "assets/pack.png";
import Star from "assets/star.svg";
import Error from "assets/error.svg";
import styles from "./Mint.module.scss";

const MIN_MINT = 1;
const MAX_MINT = 2;
const ALLOWED_VALUES = [`${MIN_MINT}`, `${MAX_MINT}`];

export const Mint = () => {
  const [counter, setCounter] = useState(1);
  const [mintState, setMintState] = useState({
    vipProof: {
      proof: 0,
      type: "LCC",
    },
    isLoading: true,
    showSuccess: false,
  });
  const [ownedPacks, setOwnedPacks] = useState([]);
  const { walletId } = useAuth();
  const packsContract = usePackContract();

  const mintFunctionName = useMemo(() => {
    if (mintState.vipProof.type === "LCC") {
      return "preMint(uint256,uint256)";
    } else return "preMint(uint256,bytes32[])";
  }, [mintState.vipProof.type]);
  const { balanceOfData } = usePackContractRead("balanceOf", [walletId], true);
  const { getAvailableSupplyData } = usePackContractRead(
    "getAvailableSupply",
    [],
    true
  );
  const { isLoading, vipProof, showSuccess } = mintState;
  const mint = usePacksContractWrite(mintFunctionName, {}, [
    counter,
    mintState.vipProof.proof,
  ]);
  const { getInitialSupplyData } = usePackContractRead("getInitialSupply");

  const mintFunction = mint[`${mintFunctionName}Write`];
  const mintError = mint[`${mintFunctionName}Error`];
  const mintSuccess = mint[`${mintFunctionName}Success`];
  const mintLoading = mint[`${mintFunctionName}Loading`];
  const canMintVip =
    vipProof.proof &&
    ownedPacks.length < MAX_MINT &&
    getAvailableSupplyData?.toNumber() > counter + ownedPacks.length &&
    !mintError?.message?.includes("already received max number");
  const soldOut = getAvailableSupplyData?.toNumber() === 0;
  const amountMinted =
    (getInitialSupplyData?.toNumber() || 0) -
    (getAvailableSupplyData?.toNumber() || 0);

  useEffect(() => {
    (async () => {
      if (!walletId) return;
      try {
        let vipProof = {
          proof: null,
          type: "",
        };
        const { ownedTokens } = await cryptoClubHolder(walletId);
        if (ownedTokens && ownedTokens.length) {
          vipProof = {
            proof: Number(ownedTokens[0].id),
            type: "LCC",
          };
        } else {
          const { merkleProof } = await area54MerkleProof(walletId);
          if (merkleProof.length) {
            vipProof = {
              proof: merkleProof,
              type: "merkle",
            };
          }
        }
        setMintState((prevMintState) => ({
          ...prevMintState,
          ...mintState,
          vipProof,
        }));
      } catch (e) {
        setMintState((prevMintState) => ({
          ...prevMintState,
          vipProof: {
            proof: 0,
            type: "LCC",
          },
        }));
      } finally {
        setMintState((prevMintState) => ({
          ...prevMintState,
          isLoading: false,
        }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletId]);

  useEffect(() => {
    if (mintSuccess) {
      setMintState((prevMintState) => ({
        ...prevMintState,
        showSuccess: true,
      }));
      const timeOut = setTimeout(() => {
        setMintState((prevMintState) => ({
          ...prevMintState,
          showSuccess: false,
        }));
        clearTimeout(timeOut);
      }, 10000);
    }
  }, [mintSuccess]);

  useEffect(() => {
    (async () => {
      if (!balanceOfData?.toNumber()) return;
      const tokensOwned = await Promise.all(
        Array(balanceOfData?.toNumber())
          .fill()
          .map(async (x, i) => {
            const tokenId = await packsContract.tokenOfOwnerByIndex(
              walletId,
              i
            );
            return tokenId.toNumber();
          })
      );
      setOwnedPacks(tokensOwned);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceOfData?.toNumber()]);

  const mintPack = () => {
    if (
      counter > getAvailableSupplyData?.toNumber() ||
      ownedPacks.length + counter > MAX_MINT
    )
      return;
    try {
      mintFunction();
    } catch (e) {
      console.error(e);
    }
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (!ALLOWED_VALUES.includes(key) || !canMintVip) return;
    const value = Number(e.key);
    if (counter === 1 && !value) setCounter(MAX_MINT);
    else setCounter(Math.max(MIN_MINT, Math.min(MAX_MINT, value)));
  };

  return (
    <div className={styles.mintContainer}>
      <Marquee />
      <SocialHeader mint />
      <section className={styles.mintPacks}>
        {!!amountMinted && (
          <h2>
            PACKS MINTED:{" "}
            <span>
              {amountMinted}/{getInitialSupplyData?.toNumber()}
            </span>
          </h2>
        )}
        <img
          className={styles.mintHeaderDesktop}
          src={MintHeaderDesktop}
          alt="mint-header"
        />
        <img
          className={styles.mintHeaderMobile}
          src={MintHeaderMobile}
          alt="mint-header"
        />
        <h1>Mint Your packs</h1>
        <p>
          The party from the edge of the galaxy has arrived! From the mind of
          Ron English comes an alien-themed collectible card set with over 350
          handmade pieces. Claim your pack to join this intergalactic
          get-together!
        </p>
        {soldOut ? (
          <div className={styles.soldOut}>
            <p>Area54 packs sold out</p>
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            <div className={styles.mintButton}>
              <button
                className={`btn ${styles.pill} ${styles.pillLeft}`}
                type="button"
                onClick={() => {
                  if (counter - 1 >= MIN_MINT)
                    setCounter((prevCounter) => prevCounter - 1);
                }}
                disabled
              >
                &ndash;
              </button>
              <input
                className={
                  !mintFunction || mintError || !canMintVip
                    ? styles.disabledInput
                    : ""
                }
                type="number"
                value={counter}
                disabled
                onKeyDown={handleKeyDown}
              />
              <button
                className={`btn ${styles.pill} ${styles.pillRight}`}
                type="button"
                onClick={() => {
                  if (counter + 1 <= MAX_MINT)
                    setCounter((prevCounter) => prevCounter + 1);
                }}
                disabled
              >
                +
              </button>
            </div>
            <ConnectButton
              className="btn"
              onClick={mintPack}
              disabled={
                !mintFunction ||
                isLoading ||
                !!mintError ||
                mintLoading ||
                !canMintVip
              }
            />
            <div className={styles.etherCost}>
              <img src={Star} alt="eth" />
              <p>0.06 ETH</p>
            </div>
          </div>
        )}
      </section>
      {walletId ? (
        <>
          <div className={`${styles.noClub} ${styles.warning}`}>
            <img src={Error} alt="error" />
            <p>VIP is sold out, please return for the public sale.</p>
          </div>
          {!vipProof.proof && !isLoading && (
            <div className={styles.noClub}>
              <img src={Error} alt="error" />
              <p>
                Sorry, VIP Minting is for{" "}
                <a
                  href="https://opensea.io/collection/lightcultcryptoclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  Light Cult Crypto Club
                </a>{" "}
                holders only. Check back on Nov. 11 for the Public Mint!
              </p>
            </div>
          )}
          {ownedPacks.length > MAX_MINT && !showSuccess && (
            <div className={`${styles.noClub} ${styles.warning}`}>
              <img src={Error} alt="error" />
              <p>Max number of packs for VIP reached</p>
            </div>
          )}
          {mintError?.message?.includes("insufficient funds") && (
            <div className={styles.noClub}>
              <img src={Error} alt="error" />
              <p>Insufficient funds for this amount of packs</p>
            </div>
          )}
          {mintError?.message &&
            !mintError?.message?.includes("insufficient funds") &&
            !mintError?.message?.includes("sale is not open") &&
            canMintVip && (
              <div className={styles.noClub}>
                <img src={Error} alt="error" />
                <p>Unable to process transaction, please try again.</p>
              </div>
            )}
          {getAvailableSupplyData?.toNumber() < counter + ownedPacks.length && (
            <div className={styles.noClub}>
              <img src={Error} alt="error" />
              <p>There are not enough packs left to mint that amount</p>
            </div>
          )}
          {showSuccess && (
            <div
              className={styles.noClub}
              style={{ backgroundColor: "#69c333" }}
            >
              <p>
                Congratulations! You should see your new pack(s) in the section
                below after the transaction is confirmed.
              </p>
            </div>
          )}
          <hr />
          {ownedPacks.length ? (
            <section className={styles.yourPacks}>
              <h1>Your Packs</h1>
              <div className={styles.packContainer}>
                {ownedPacks.map((ownedPack) => (
                  <Pack packId={ownedPack} key={`pack-${ownedPack}`} />
                ))}
              </div>
            </section>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default Mint;

import { GetNotified, Marquee, SocialHeader } from "components";
import footerSvg from "assets/footer-pattern.svg";
import area54MobileSvg from "assets/area54-mobile.svg";
import area54DesktopSvg from "assets/area54-desktop.svg";
import landingMobileSvg from "assets/landing-soon-mobile.svg";
import landingDesktopSvg from "assets/landing-soon-desktop.svg";
import styles from "./ComingSoon.module.scss";

export const ComingSoon = () => {
  return (
    <div className={styles.coming}>
      <Marquee />
      <SocialHeader />
      <div className={styles.comingSoon}>
        <img
          src={landingMobileSvg}
          alt="Landing Soon"
          className={styles.landingMobile}
        />
        <img
          src={landingDesktopSvg}
          alt="Landing Soon"
          className={styles.landingDesktop}
        />
        <p className={styles.description}>
          The party from the edge of the galaxy has arrived! From the mind of
          Ron English comes an alien-themed collectable card set with over 350
          handmade pieces. Claim your pack to join this intergalactic
          get-together!
        </p>
        <GetNotified />
        <img
          src={area54MobileSvg}
          alt="Area 54"
          className={styles.area54Mobile}
        />
        <img
          src={area54DesktopSvg}
          alt="Area 54"
          className={styles.area54Desktop}
        />
        <footer className={styles.footer}>
          <img src={footerSvg} alt="footer" />
        </footer>
      </div>
    </div>
  );
};

export default ComingSoon;

import { useContractWrite, usePrepareContractWrite } from "wagmi";
import area54PacksAbi from "abi/Area54Packs.json";

const PACKS_CONTRACT_ADDRESS = process.env.REACT_APP_PACKS_CONTRACT_ADDRESS;

export function usePacksContractWrite(functionName, overrides, args = []) {
  const { config, error } = usePrepareContractWrite({
    address: PACKS_CONTRACT_ADDRESS,
    abi: area54PacksAbi,
    functionName,
    overrides,
    args,
  });

  const { write, isLoading, isSuccess } = useContractWrite(config);

  return {
    [`${functionName}Write`]: write,
    [`${functionName}Error`]: error,
    [`${functionName}Loading`]: isLoading,
    [`${functionName}Success`]: isSuccess,
  };
}

import PropTypes from "prop-types";
import styles from "./VipCard.module.scss";

const CARDS_BUCKET = process.env.REACT_APP_CARD_BUCKET;

export const VipCard = ({ name, owned, number }) => {
  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <img src={`${CARDS_BUCKET}/${number}.png`} alt="vip-card" />
      </div>
      <div
        className={styles.caption}
        style={{ backgroundColor: owned ? "#FFF" : "transparent" }}
      >
        <span>{owned ? "owned" : "missing"}</span>
        <h4 style={{ opacity: owned ? "1" : "0.8" }}>{name}</h4>
      </div>
    </div>
  );
};

VipCard.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  owned: PropTypes.bool.isRequired,
};

export default VipCard;

import { useContract, useProvider } from "wagmi";
import area54PacksAbi from "abi/Area54Packs.json";

const PACKS_CONTRACT_ADDRESS = process.env.REACT_APP_PACKS_CONTRACT_ADDRESS;

export function usePackContract() {
  const provider = useProvider();
  const contract = useContract({
    address: PACKS_CONTRACT_ADDRESS,
    abi: area54PacksAbi,
    signerOrProvider: provider,
  });

  return contract;
}

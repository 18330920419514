import { configureChains, chain } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
// import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";

const alchemyRpcUrl = process.env.REACT_APP_ALCHEMY_RPC_URL;
const apiKey = alchemyRpcUrl
  ? alchemyRpcUrl.split("/")[alchemyRpcUrl.split("/").length - 1]
  : null;

export const { chains, provider, webSocketProvider } = configureChains(
  [chain.mainnet, chain.goerli],
  [
    alchemyProvider({ apiKey }),
    // publicProvider(),
  ]
);

export const connectors = () => {
  return alchemyRpcUrl
    ? [
        new MetaMaskConnector({
          chains,
        }),
      ]
    : undefined;
};

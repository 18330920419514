import { useContract, useProvider } from "wagmi";
import area54CardsAbi from "abi/Area54Cards.json";

const CARDS_CONTRACT_ADDRESS = process.env.REACT_APP_CARDS_CONTRACT_ADDRESS;

export function useCardsContract() {
  const provider = useProvider();
  const contract = useContract({
    address: CARDS_CONTRACT_ADDRESS,
    abi: area54CardsAbi,
    signerOrProvider: provider,
  });

  return contract;
}

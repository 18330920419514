import { WALLET_API } from "constants";
import { fetchApi } from "utils";

export async function cryptoClubHolder(walletAddress) {
  const chainId =
    process.env.REACT_APP_REACT_ENV !== "prod" ? "?chainId=5" : "";
  const data = await fetchApi(
    `${WALLET_API}/wallets/AREA54/${walletAddress}${chainId}`,
    "get"
  );
  return { ownedTokens: data?.ownedTokens };
}

import { useEffect, useState } from "react";
import { ethers } from "ethers";
import {
  useAuth,
  usePackContractRead,
  usePacksContractWrite,
  useCardContractRead,
} from "hooks";
import { Card, Marquee, Pack, SocialHeader, ConnectButton } from "components";
import { PACK_PRICE } from "constants";
import MintHeaderDesktop from "assets/mint-header-desktop.png";
import MintHeaderMobile from "assets/pack.png";
import Star from "assets/star.svg";
import Error from "assets/error.svg";
import styles from "./PublicMint.module.scss";
import { usePackContract } from "hooks/usePackContract";

const MIN_MINT = 1;
const MAX_MINT = 2500;

export const PublicMint = () => {
  const [counter, setCounter] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);
  const [ownedPacks, setOwnedPacks] = useState([]);

  const { walletId } = useAuth();
  const packsContract = usePackContract();

  const { balanceOfData } = usePackContractRead("balanceOf", [walletId], true);

  const { getAvailableSupplyData } = usePackContractRead(
    "getAvailableSupply",
    [],
    true
  );
  const mint = usePacksContractWrite("mint", {
    from: walletId,
    value: ethers.utils.parseEther(`${PACK_PRICE * counter}`),
  });
  const { getInitialSupplyData } = usePackContractRead("getInitialSupply");
  const { balanceOfData: balanceOfCards } = useCardContractRead(
    "balanceOf",
    [walletId],
    true
  );

  const mintFunction = mint["mintWrite"];
  const mintError = mint["mintError"];
  const mintSuccess = mint["mintSuccess"];
  const mintLoading = mint["mintLoading"];
  const canMint =
    balanceOfData?.toNumber() + counter < getAvailableSupplyData?.toNumber();
  const soldOut = getAvailableSupplyData?.toNumber() === 0;
  const amountMinted =
    (getInitialSupplyData?.toNumber() || 0) -
    (getAvailableSupplyData?.toNumber() || 0);
  const ownedCards = Array.from(Array(balanceOfCards?.toNumber() || 0).keys());

  useEffect(() => {
    if (mintSuccess) {
      setShowSuccess(true);
      const timeOut = setTimeout(() => {
        setShowSuccess(false);
        clearTimeout(timeOut);
      }, 10000);
    }
  }, [mintSuccess]);

  useEffect(() => {
    (async () => {
      const tokensOwned = await Promise.all(
        Array(balanceOfData?.toNumber())
          .fill()
          .map(async (x, i) => {
            const tokenId = await packsContract.tokenOfOwnerByIndex(
              walletId,
              i
            );
            return tokenId.toNumber();
          })
      );
      setOwnedPacks(tokensOwned);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceOfData?.toNumber()]);

  const mintPack = () => {
    if (counter > getAvailableSupplyData?.toNumber()) return;
    try {
      mintFunction();
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (event) => {
    const value = Math.max(
      MIN_MINT,
      Math.min(MAX_MINT, Number(event.target.value))
    );
    setCounter(value);
  };

  return (
    <div className={styles.mintContainer}>
      <Marquee />
      <SocialHeader mint />
      <section className={styles.mintPacks}>
        {!!amountMinted && (
          <h2>
            PACKS MINTED:{" "}
            <span>
              {amountMinted}/{getInitialSupplyData?.toNumber()}
            </span>
          </h2>
        )}
        <img
          className={styles.mintHeaderDesktop}
          src={MintHeaderDesktop}
          alt="mint-header"
        />
        <img
          className={styles.mintHeaderMobile}
          src={MintHeaderMobile}
          alt="mint-header"
        />
        <h1>Mint Your packs</h1>
        <p>
          The party from the edge of the galaxy has arrived! From the mind of
          Ron English comes an alien-themed collectible card set with over 350
          handmade pieces. Claim your pack to join this intergalactic
          get-together!
        </p>
        {soldOut ? (
          <div className={styles.soldOut}>
            <p>Area54 packs sold out</p>
          </div>
        ) : (
          <div className={styles.packsToMint}>
            <p className={styles.mintLabel}>Number of packs to mint</p>
            <div className={styles.mintButton}>
              <button
                className={`btn ${styles.pill} ${styles.pillLeft}`}
                type="button"
                onClick={() => {
                  if (counter - 1 >= MIN_MINT)
                    setCounter((prevCounter) => prevCounter - 1);
                }}
              >
                &ndash;
              </button>
              <input
                className={
                  !mintFunction || mintError ? styles.disabledInput : ""
                }
                type="number"
                value={counter}
                onChange={handleChange}
              />
              <button
                className={`btn ${styles.pill} ${styles.pillRight}`}
                type="button"
                onClick={() => {
                  if (counter + 1 <= MAX_MINT)
                    setCounter((prevCounter) => prevCounter + 1);
                }}
              >
                +
              </button>
            </div>
            <ConnectButton
              className="btn"
              onClick={mintPack}
              disabled={!mintFunction || !!mintError || mintLoading}
            />
            <div className={styles.etherCost}>
              <img src={Star} alt="eth" />
              <p>{`${PACK_PRICE} ETH`}</p>
            </div>
          </div>
        )}
      </section>

      {walletId ? (
        <>
          {mintError?.message?.includes("insufficient funds") && !showSuccess && (
            <div className={styles.noClub}>
              <img src={Error} alt="error" />
              <p>Insufficient funds for this amount of packs</p>
            </div>
          )}
          {mintError?.message &&
            !mintError?.message?.includes("insufficient funds") &&
            !mintError?.message?.includes("sale is not open") &&
            canMint && (
              <div className={styles.noClub}>
                <img src={Error} alt="error" />
                <p>Unable to process transaction, please try again.</p>
              </div>
            )}
          {!canMint && !showSuccess && (
            <div className={styles.noClub}>
              <img src={Error} alt="error" />
              <p>There are not enough packs left to mint that amount</p>
            </div>
          )}
          {showSuccess && (
            <div
              className={styles.noClub}
              style={{ backgroundColor: "#69c333" }}
            >
              <p>
                Congratulations! You should see your new pack(s) in the section
                below after the transaction is confirmed.
              </p>
            </div>
          )}
          {ownedPacks.length ? (
            <>
              <hr />
              <section className={styles.yourPacks}>
                <h1>Your Packs</h1>
                <div className={styles.packContainer}>
                  {ownedPacks.map((ownedPack) => (
                    <Pack packId={ownedPack} key={`pack-${ownedPack}`} />
                  ))}
                </div>
              </section>
            </>
          ) : null}
          {ownedCards.length ? (
            <>
              <hr />
              <section className={styles.yourCards}>
                <h1>Your Cards</h1>
                <div
                  className={`${styles.packContainer} ${styles.cardContainer}`}
                >
                  {ownedCards.map((ownedCard) => (
                    <Card
                      key={`card-${ownedCard}`}
                      id={ownedCard}
                      walletId={walletId}
                    />
                  ))}
                </div>
              </section>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default PublicMint;

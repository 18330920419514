import PropTypes from "prop-types";
import { getStage } from "utils";
import { stages } from "constants";
import { OpenPack } from "..";
import PackImage from "assets/pack.png";
import styles from "./Pack.module.scss";

const stage = getStage();

export const Pack = ({ packId }) => {
  return (
    <div className={styles.pack}>
      <div className={styles.imageContainer}>
        <img src={PackImage} alt="pack" />
      </div>
      <div className={styles.caption}>
        <h4>Pack #{packId}</h4>
        {stage === stages.stage3 && (
          <button className="btn" disabled>
            Open Nov 11
          </button>
        )}
        {[stages.stage4, stages.stage5].includes(stage) && (
          <OpenPack pack={packId} />
        )}
      </div>
    </div>
  );
};

Pack.propTypes = {
  packId: PropTypes.number.isRequired,
};

export default Pack;

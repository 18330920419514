import axios from "axios";

export async function fetchApi(endpoint, method, body = {}) {
  let data = null;
  try {
    const response = await axios[method](endpoint, body);
    data = response.data;
  } catch (e) {
    const error = new Error(e);
    error.response = e?.response?.data;
    throw error;
  }
  return data;
}

import PropTypes from "prop-types";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useConnect, useDisconnect } from "wagmi";
import { Icon } from "components";
import { useScroll, useAuth } from "hooks";
import { stages, routes } from "constants";
import { getStage, secretPrizeExpired } from "utils";
import ronEnglishPng from "assets/ron-english-area54.png";
import styles from "./SocialHeader.module.scss";

const stage = getStage();

export const SocialHeader = ({ mint, hideSecretPrize = false }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const { connect, connectors, isLoading, pendingConnector } = useConnect();
  const { disconnect } = useDisconnect();
  const { isAuthorized, walletId } = useAuth();
  const scrollPosition = useScroll();
  const navigate = useNavigate();

  function toggleMenu() {
    setOpenMenu((value) => !value);
    setOpenDisconnect(false);
  }

  function handleDisconnect(e) {
    e.preventDefault();
    disconnect();
    setOpenDisconnect(false);
    setOpenMenu(false);
  }

  return [stages.stage1, stages.stage2].includes(stage) ? (
    <div
      className={`${styles.socialHeader} ${
        scrollPosition > 1 ? styles.fixed : ""
      }`}
    >
      <img
        src={ronEnglishPng}
        alt="Ron English AREA 54"
        className={styles.orion}
      />
      <div className={styles.socials}>
        <a
          href="https://discord.gg/eJc9K2gHTF"
          target="_blank"
          rel="noreferrer"
        >
          <Icon i="discord" />
        </a>
        <a
          href="https://twitter.com/area54club?s=21&t=HN6pz-z78ieNk3W1O5DCzQ"
          target="_blank"
          rel="noreferrer"
        >
          <Icon i="twitter" />
        </a>
      </div>
    </div>
  ) : (
    <div className={`${styles.menu} ${openMenu ? styles.open : ""}`}>
      <Link to={routes.HOME}>
        <img
          src={ronEnglishPng}
          alt="Ron English AREA 54"
          className={styles.orion}
        />
      </Link>
      <button
        type="button"
        className={`btn btn-primary ${styles.toggle}`}
        onClick={toggleMenu}
      >
        {openMenu ? "CLOSE" : "MENU"}
      </button>
      <div className={styles.content}>
        {stage === stages.stage5 && !hideSecretPrize && (
          <button
            className={`btn btn-primary ${styles.mint}`}
            onClick={() => navigate(routes.SECRET)}
            disabled={secretPrizeExpired()}
          >
            TOP SECRET PRIZE
          </button>
        )}
        {!mint && (
          <Link to={routes.MINT} className={`btn btn-primary ${styles.mint}`}>
            MINT
          </Link>
        )}
        {isAuthorized ? (
          <>
            <button
              type="button"
              className={`btn btn-primary ${styles.connect}`}
              onClick={() => setOpenDisconnect(true)}
            >
              {walletId}
            </button>
          </>
        ) : (
          <>
            {connectors[0]?.ready ? (
              <button
                disabled={
                  isLoading && pendingConnector?.id === connectors[0]?.id
                }
                onClick={() => connect({ connector: connectors[0] })}
                className={`btn btn-primary ${styles.connect}`}
              >
                Connect Wallet
                {isLoading &&
                  pendingConnector?.id === connectors[0]?.id &&
                  " (connecting)"}
              </button>
            ) : (
              <a
                href="https://metamask.app.link/dapp/area54.io"
                target="_blank"
                rel="noreferrer"
                className={`btn btn-primary ${styles.connect}`}
              >
                Connect Wallet
              </a>
            )}
          </>
        )}

        <div className={styles.socials}>
          <a
            href="https://twitter.com/area54club?s=21&t=HN6pz-z78ieNk3W1O5DCzQ"
            target="_blank"
            rel="noreferrer"
          >
            <Icon i="twitter" />
          </a>
          <a
            href="https://discord.gg/eJc9K2gHTF"
            target="_blank"
            rel="noreferrer"
          >
            <Icon i="discord" />
          </a>
        </div>
      </div>

      <form
        onSubmit={handleDisconnect}
        className={`${styles.disconnect} ${openDisconnect ? styles.show : ""}`}
      >
        <p>ARE YOU SURE YOU WANT TO DISCONNECT YOUR WALLET?</p>
        <button type="submit" className="btn btn-primary">
          YES
        </button>
        <button
          type="button"
          className="btn btn-link"
          onClick={() => setOpenDisconnect(false)}
        >
          NO
        </button>
      </form>
    </div>
  );
};

SocialHeader.propTypes = {
  mint: PropTypes.bool,
  hideSecretPrize: PropTypes.bool,
};

export default SocialHeader;

import { useState, useEffect } from "react";

export const useScroll = () => {
  const getScrollPosition = () => {
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    return Math.round(
      (window.document.documentElement.scrollTop / height) * 100
    );
  };

  const [scrollPosition, setScrollPosition] = useState(getScrollPosition());

  useEffect(() => {
    const onScroll = () => setScrollPosition(getScrollPosition());
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return scrollPosition;
};

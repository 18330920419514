import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useNetwork, useSignMessage } from "wagmi";
import { SiweMessage } from "siwe";
import { Marquee, SocialHeader } from "components";
import { useValidateVipCards } from "hooks";
import { routes } from "constants";
import { vipPrize } from "services/vipPrize";
import claimCrocsMobile from "assets/claim-your-crocs-mobile.svg";
import claimCrocsDesktop from "assets/claim-your-crocs-desktop.svg";
import crocsPng from "assets/crocs.png";
import errorSvg from "assets/error.svg";
import successSvg from "assets/success.svg";
import styles from "./ClaimYourCrocs.module.scss";
import { secretPrizeExpired } from "utils";

export const ClaimYourCrocs = () => {
  const [form, setForm] = useState({ size: "" });
  const [claiming, setClaiming] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState([]);
  const { ownsAll, loading } = useValidateVipCards();
  const navigate = useNavigate();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage();

  const expireDate = new Intl.DateTimeFormat("en", {
    month: "long",
    day: "numeric",
  }).format(new Date(process.env.REACT_APP_SECRET_PRIZE_EXPIRE_DATE));

  const hasSecretPriceExpired = secretPrizeExpired();

  useEffect(() => {
    if (!loading && !ownsAll && hasSecretPriceExpired) {
      navigate(routes.SECRET);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  function handleChange(e) {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFieldErrors((prev) => prev.filter((error) => error !== e.target.name));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError(null);
      setFieldErrors([]);
      const errors = [];
      if (!form.name) errors.push("name");
      if (!form.street) errors.push("street");
      if (!form.city) errors.push("city");
      if (!form.state) errors.push("state");
      if (!form.zipcode) errors.push("zipcode");
      if (!form.country) errors.push("country");
      if (!form.size) errors.push("size");
      if (
        !form.email ||
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          form.email
        )
      )
        errors.push("email");
      if (errors.length) {
        setFieldErrors(errors);
        setError("Please fill all the fields correctly");
        return;
      }
      setClaiming(true);
      // Creates SIWE message
      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement: "Sign in with Ethereum to the app.",
        uri: window.location.origin,
        version: "1",
        chainId: chain?.id,
      });
      // Signs message
      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      });
      const data = await vipPrize({
        walletAddress: address,
        message,
        signature,
        shippingAddress: form,
      });
      if (data.valid) {
        setSuccessful(true);
      } else {
        setError("Error when validating wallet address. Please try again.");
      }
    } catch (err) {
      console.error(err);
      if (
        err?.response?.message ===
        "The specified wallet address is already in use."
      ) {
        setError("Sorry, you've already claimed your free Crocs.");
      } else {
        setError("Error when validating wallet address. Please try again.");
      }
    } finally {
      setClaiming(false);
    }
  }

  return (
    <div className={styles.claimCrocs}>
      <Marquee />
      <SocialHeader hideSecretPrize={true} />
      <div className={styles.claimCorsContent}>
        <h1>OFFER EXPIRES {expireDate}st or until supplies last</h1>
        <img
          src={claimCrocsMobile}
          alt="Claim Your Crocs"
          className={styles.claimCrocsMobile}
        />
        <img
          src={claimCrocsDesktop}
          alt="Claim Your Crocs"
          className={styles.claimCrocsDesktop}
        />
        <img src={crocsPng} alt="Crocs" className={styles.crocsImage} />
        <p className={styles.description}>
          Ron English has teamed up with Crocs x Foot Locker to design an
          exclusive collection inspired by Area 54— it features cosmic designs
          and 3D alien Jibbitz that defy gravity. And as a VIP Card Collection
          holder, you&apos;ve got first-in-line access to the drop. Click below
          to claim your pair of Ron English Crocs. Full launch lands on 11/22 at
          Foot Locker retail locations.
        </p>
        <form onSubmit={handleSubmit}>
          <label>Name</label>
          <input
            placeholder="Name"
            name="name"
            type="text"
            className={fieldErrors.includes("name") ? styles.error : ""}
            onChange={handleChange}
          />
          <label>Email</label>
          <input
            placeholder="Email"
            name="email"
            type="email"
            className={fieldErrors.includes("email") ? styles.error : ""}
            onChange={handleChange}
          />
          <label>Address</label>
          <input
            placeholder="Street"
            name="street"
            type="text"
            className={fieldErrors.includes("street") ? styles.error : ""}
            onChange={handleChange}
          />
          <div className={styles.cityState}>
            <input
              placeholder="City"
              name="city"
              type="text"
              className={fieldErrors.includes("city") ? styles.error : ""}
              onChange={handleChange}
            />
            <input
              placeholder="State"
              name="state"
              type="text"
              className={fieldErrors.includes("state") ? styles.error : ""}
              onChange={handleChange}
            />
          </div>
          <div className={styles.zipcodeCountry}>
            <input
              placeholder="Zip Code"
              name="zipcode"
              type="text"
              className={fieldErrors.includes("zipcode") ? styles.error : ""}
              onChange={handleChange}
            />
            <input
              placeholder="Country"
              name="country"
              type="text"
              className={fieldErrors.includes("country") ? styles.error : ""}
              onChange={handleChange}
            />
          </div>
          <label>Shoe Size</label>
          <select
            name="size"
            value={form.size}
            className={fieldErrors.includes("size") ? styles.error : ""}
            onChange={handleChange}
          >
            <option disabled={true} value="">
              Select your shoe size
            </option>
            <option value="M2">M2</option>
            <option value="M3">M3</option>
            <option value="M4">M4</option>
            <option value="M5">M5</option>
            <option value="M6">M6</option>
            <option value="M7">M7</option>
            <option value="M8">M8</option>
            <option value="M9">M9</option>
            <option value="M10">M10</option>
            <option value="M12">M12</option>
            <option value="M13">M13</option>
            <option value="M14">M14</option>
            <option value="M15">M15</option>
            <option value="C4">C4</option>
            <option value="C5">C5</option>
            <option value="C6">C6</option>
            <option value="C7">C7</option>
            <option value="C8">C8</option>
            <option value="C9">C9</option>
            <option value="C10">C10</option>
            <option value="C11">C11</option>
            <option value="C12">C12</option>
            <option value="C13">C13</option>
            <option value="J1">J1</option>
            <option value="J2">J2</option>
            <option value="J3">J3</option>
            <option value="J4">J4</option>
            <option value="J5">J5</option>
            <option value="J6">J6</option>
          </select>
          <button
            type="submit"
            className="btn"
            disabled={loading || claiming || successful}
          >
            {claiming ? "Claiming..." : "Claim Your Crocs"}
          </button>
        </form>
        {error && (
          <div className={`${styles.message} ${styles.error}`}>
            <img src={errorSvg} alt="error" />
            <p>{error}</p>
          </div>
        )}
        {successful && (
          <div className={`${styles.message} ${styles.success}`}>
            <img src={successSvg} alt="success" />
            <p>Congratulations! You successfuly claimed your Area 54 Crocs!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimYourCrocs;

import { useState } from "react";
import styles from "./GetNotified.module.scss";

export const GetNotified = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={styles.getNotified}>
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => setOpen(true)}
      >
        Get Notified
      </button>
      {isOpen && (
        <div className={styles.dialog} onClick={() => setOpen(false)}>
          <div className={styles.content} onClick={(e) => e.stopPropagation()}>
            <iframe
              title="Get Notified"
              src={process.env.REACT_APP_LAYLO_DROP}
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              allowtransparency="true"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GetNotified;

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useConnect } from "wagmi";
import { useAuth } from "hooks";

export const ConnectButton = ({ className, onClick, disabled }) => {
  const { connect, connectors, isLoading, pendingConnector } = useConnect();
  const { isAuthorized } = useAuth();
  const navigate = useNavigate();
  const handleClick = onClick || (() => navigate("/mint"));
  return (
    <>
      {isAuthorized ? (
        <>
          <button
            type="button"
            disabled={disabled}
            className={className}
            onClick={handleClick}
          >
            Mint your packs
          </button>
        </>
      ) : (
        <>
          {connectors[0]?.ready ? (
            <button
              disabled={isLoading && pendingConnector?.id === connectors[0]?.id}
              onClick={() => connect({ connector: connectors[0] })}
              className={className}
            >
              Connect Wallet
            </button>
          ) : (
            <a
              href="https://metamask.app.link/dapp/area54.io"
              target="_blank"
              rel="noreferrer"
              className={className}
            >
              Connect Wallet
            </a>
          )}
        </>
      )}
    </>
  );
};

ConnectButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ConnectButton;

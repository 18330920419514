import { useNavigate } from "react-router-dom";
import { Marquee, SocialHeader, VipCard } from "components";
import { useValidateVipCards } from "hooks";
import { routes } from "constants";
import secretHeaderDesktop from "assets/secret-prize-desktop-header.svg";
import secretHeaderMobile from "assets/secret-prize-mobile-header.svg";
import styles from "./SecretPrize.module.scss";
import { secretPrizeExpired } from "utils";

export const SecretPrize = () => {
  const validatedCards = useValidateVipCards();
  const navigate = useNavigate();

  return (
    <div className={styles.secret}>
      <Marquee />
      <SocialHeader hideSecretPrize={true} />
      <section className={styles.headerSection}>
        <img
          className={styles.secretDesktop}
          src={secretHeaderDesktop}
          alt="header-desktop"
        />
        <img
          className={styles.secretMobile}
          src={secretHeaderMobile}
          alt="header-mobile"
        />
        <p className={styles.headerText}>
          We have a special surprise gift for you! Collect one of each VIP card
          listed below for a special gift.
        </p>

        <button
          className={`${styles.headerButton} btn
        `}
          disabled={!validatedCards.ownsAll || secretPrizeExpired()}
          onClick={() => navigate(routes.CLAIM_YOUR_CROCS)}
        >
          {validatedCards.ownsAll
            ? "Claim your special gift"
            : "Collect All VIP cards"}
        </button>
      </section>
      <section>
        <div className={styles.vipCardContainer}>
          {validatedCards.ownedVipCards.map((vipCard) => (
            <VipCard
              key={`${vipCard.name}-${vipCard.number}`}
              name={vipCard.name}
              number={vipCard.number}
              owned={vipCard.owned}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default SecretPrize;

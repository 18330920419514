import { useState } from "react";
import {
  Marquee,
  SocialHeader,
  GetNotified,
  Icon,
  ConnectButton,
} from "components";
import { stages } from "constants";
import { getStage } from "utils";
import area54MobileSvg from "assets/area54-mobile.svg";
import area54DesktopSvg from "assets/area54-desktop.svg";
import landingMobileSvg from "assets/claim-alien-mobile.svg";
import landingDesktopSvg from "assets/claim-alien-desktop.svg";
import mintMobileSvg from "assets/mint-your-packs-mobile.svg";
import mintDesktopSvg from "assets/mint-your-packs-desktop.svg";
import pack1Png from "assets/pack1.png";
import thePacksSvg from "assets/the-packs.svg";
import looksRareMobileSvg from "assets/looks-rare-mobile.svg";
import looksRareDesktopSvg from "assets/looks-rare-desktop.svg";
import fromRonMobileSvg from "assets/from-ron-english-mobile.svg";
import fromRonDesktopSvg from "assets/from-ron-english-desktop.svg";
import ronEnglishPng from "assets/ron-english.png";
import aliensSvg from "assets/aliens.svg";
import faqMobileSvg from "assets/faq-mobile.svg";
import faqDesktopSvg from "assets/faq-desktop.svg";
import card1Gif from "assets/card1.gif";
import card2Gif from "assets/card2.gif";
import card3Gif from "assets/card3.gif";
import card4Gif from "assets/card4.gif";
import cardPopUpGif from "assets/card-pop-up.gif";
import styles from "./Landing.module.scss";
import { useEffect } from "react";

const stage = getStage();

export const Landing = () => {
  const [faqOpen, setFaqOpen] = useState(null);
  const [thePacksAnim, setThePacksAnim] = useState(cardPopUpGif);

  useEffect(() => {
    const animation = setInterval(() => {
      setThePacksAnim(null);
      setTimeout(() => setThePacksAnim(cardPopUpGif), 0);
    }, 10000);
    return () => clearInterval(animation);
  }, []);

  function toogleFaq(item) {
    setFaqOpen(faqOpen === item ? null : item);
  }

  return (
    <div className={styles.landing}>
      <Marquee />
      <SocialHeader />
      <section className={styles.landingSoon}>
        <img
          src={landingMobileSvg}
          alt="Landing Soon"
          className={styles.landingMobile}
        />
        <img
          src={landingDesktopSvg}
          alt="Landing Soon"
          className={styles.landingDesktop}
        />
        <p className={styles.description}>
          The party from the edge of the galaxy has arrived! From the mind of
          Ron English comes an alien-themed collectable card set with over 350
          handmade pieces. Claim your pack to join this intergalactic
          get-together!
        </p>
        {[stages.stage1, stages.stage2].includes(stage) ? (
          <>
            <GetNotified />
            {stage === stages.stage2 && (
              <div className={styles.price}>
                <span>0.06 ETH</span>
              </div>
            )}
          </>
        ) : (
          <>
            <ConnectButton className="btn btn-primary" />
            <div className={styles.price} style={{ marginLeft: "280px" }}>
              <span>0.06 ETH</span>
            </div>
          </>
        )}
        <img
          src={area54MobileSvg}
          alt="Area 54"
          className={styles.area54Mobile}
        />
        <img
          src={area54DesktopSvg}
          alt="Area 54"
          className={styles.area54Desktop}
        />
        <Icon i="footer" className={styles.footer} />
      </section>
      <section className={styles.mintYourPack}>
        <img
          src={mintMobileSvg}
          alt="Mint your pack"
          className={styles.mintMobile}
        />
        <img
          src={mintDesktopSvg}
          alt="Mint your pack"
          className={styles.mintDesktop}
        />
        <p className={styles.description}>
          Claim your pack to join this intergalactic get-together!
        </p>
        {[stages.stage1, stages.stage2].includes(stage) ? (
          <GetNotified />
        ) : (
          <ConnectButton className="btn btn-primary" />
        )}
        <div className={styles.timeline}>Mint Timeline</div>
        {stages.stage4 !== stage && stages.stage5 !== stage && (
          <div className={styles.date}>
            <>
              <span>{stage !== stages.stage3 ? `NOV 9-10` : "TODAY"}</span>
              <span>VIP MINT</span>
              <p>
                VIP Mint is for Light Cult Crypto Club holders only. VIP packs
                will be free, but holders will not be able to open them until
                the Public Mint day. Maximum 2 per wallet of VIP or allowlist.
                1000 packs total available. Be the light{" "}
                <a
                  href="https://opensea.io/collection/lightcultcryptoclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  HERE
                </a>{" "}
                and your VIP spot is guaranteed.
              </p>
            </>
            <div className={styles.price1}>
              <span>FREE</span>
            </div>
          </div>
        )}
        <div className={styles.date}>
          <span>
            {stage !== stages.stage4 && stage !== stages.stage5
              ? `NOV 11`
              : "TODAY"}
          </span>
          <span>PUBLIC MINT</span>
          <div className={styles.price2}>
            <span>0.06 ETH</span>
          </div>
          <p>
            At least 1300 remaining packs. Total supply will be 2500 packs or
            10,000 cards.
          </p>
        </div>
        <div className={styles.date}>
          <span>
            {stage !== stages.stage4 && stage !== stages.stage5
              ? `NOV 11`
              : "TODAY"}
          </span>
          <span>PACK REVEAL</span>
          <p>All drops start at 2pm ET.</p>
        </div>
        <img src={pack1Png} alt="Pack" className={styles.pack} />
        <Icon i="footer" className={styles.footer} />
      </section>
      <section className={styles.thePacks}>
        <img
          src={thePacksSvg}
          alt="The Packs"
          className={styles.thePacksTitle}
        />
        <h1>
          2500 PACKS <br /> 10,000 TOTAL CARDS
        </h1>
        <p className={styles.description}>
          The party from the edge of the galaxy has arrived! 2500 packs, 4 cards
          per pack, come and see which rare aliens you get!
        </p>
        {[stages.stage1, stages.stage2].includes(stage) ? (
          <GetNotified />
        ) : (
          <ConnectButton className="btn btn-primary" />
        )}
        <div className={styles.pack}>
          <img src={thePacksAnim} alt="The Packs" />
        </div>
        <Icon i="footer" className={styles.footer} />
      </section>
      <section className={styles.looksRare}>
        <img
          src={looksRareMobileSvg}
          alt="Looks Rare"
          className={styles.looksRareMobile}
        />
        <img
          src={looksRareDesktopSvg}
          alt="Looks Rare"
          className={styles.looksRareDesktop}
        />
        <div className={styles.nfts}>
          <div className={styles.nft}>
            <img src={card1Gif} alt="VIP" />
            <h1>VIP</h1>
            <p>
              These flagship characters were part of the original pitch,
              including characters from short film production.
            </p>
          </div>
          <div className={styles.nft}>
            <img src={card2Gif} alt="UNCOMMON" />
            <h1>UNCOMMON</h1>
            <p>
              Handmade mixing and matching of elements to create 2+ versions of
              each face.
            </p>
          </div>
          <div className={styles.nft}>
            <img src={card3Gif} alt="RARE" />
            <h1>RARE</h1>
            <p>
              Red, Pink, Blue, and Yellow versions of each character. This is a
              nod to the coloring used in our Light Cult Crypto Club NFT
              project.
            </p>
          </div>
          <div className={styles.nft}>
            <img src={card4Gif} alt="ULTRA-RARE" />
            <h1>ULTRA-RARE</h1>
            <p>
              Monotone version of each face. Only 11 per face, including the
              alien versions of Ron&apos;s &quot;stippled&quot; designs that
              were also Ultra Rare in the LCCC project.
            </p>
          </div>
        </div>
        <Icon i="footer" className={styles.footer} />
      </section>
      <section className={styles.fromRonEnglish}>
        <img
          src={fromRonMobileSvg}
          alt="From Ron English"
          className={styles.fromRonMobile}
        />
        <img
          src={fromRonDesktopSvg}
          alt="From Ron English"
          className={styles.fromRonDesktop}
        />
        <div className={styles.ronEnglishContainer}>
          <img
            src={ronEnglishPng}
            alt="Ron English"
            className={styles.ronEnglish}
          />
          <img src={aliensSvg} alt="Aliens" className={styles.aliens} />
        </div>
        <div className={styles.info}>
          <div className={styles.infoColumn}>
            <p className={styles.description}>
              One of the most prolific and recognizable artists alive today, New
              York-based painter, street artist and designer Ron English has
              bombed the global landscape with unforgettable images across all
              categories of culture, history and art. As a fine art painter
              enjoying gallery and museum representation worldwide, Mr. English
              is well-known for his stunning technique and inventive use of
              color.
            </p>
            <p className={styles.description}>
              Widely considered the Godfather of Street Art, he has created
              murals and billboards across the globe that blend dazzling visuals
              with provocative statements.
            </p>
          </div>
          <div className={styles.infoColumn}>
            <p className={styles.description}>
              Mr. English has also been on the vanguard of the booming
              collectible figure movement, releasing hundreds of unique and
              highly coveted collectibles which continue to fetch healthy
              aftermarket prices.
            </p>
            <p className={styles.description}>
              Publications featuring Mr. English include The New York Times, The
              Wall Street Journal, USA Today, The Miami Herald, The Washington
              Post, The Los Angeles Times, LA Weekly, Art News, Time Magazine,
              Rolling Stone, Creem, Juxtapoz, Hi Fructose, Mad Magazine and many
              others.
            </p>
          </div>
          <div className={styles.infoColumn}>
            <p className={styles.description}>
              Motion picture/television appearances include Exit through the
              Gift Shop, Supersize Me, the Simpsons, Work of Art, Street Art
              Throwdown, CNN, BBC, Sky TV, and many others.
            </p>
            <a
              href="https://www.popaganda.com/"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              More about the artist
            </a>
          </div>
        </div>
        <Icon i="footer" className={styles.footer} />
      </section>
      <section className={styles.faq}>
        <img src={faqMobileSvg} alt="FAQ" className={styles.faqTitleMobile} />
        <img src={faqDesktopSvg} alt="FAQ" className={styles.faqTitleDesktop} />
        <div className={styles.items}>
          <div
            className={`${styles.item} ${faqOpen === 1 ? styles.active : ""}`}
          >
            <h1 onClick={() => toogleFaq(1)}>
              What is Ron English&apos;s Area54?
            </h1>
            <button type="button" onClick={() => toogleFaq(1)}>
              {faqOpen === 1 ? "-" : "+"}
            </button>
            <p>
              From the mind of Ron English comes an alien themed collectable
              card set with over 350 handmade pieces. A fun, mysterious,
              interactive project for fans of Ron, fans of aliens, and fans of
              dope art! This project will have special partnerships,
              announcements, giveaways, and more as it develops.
            </p>
          </div>
          <div
            className={`${styles.item} ${faqOpen === 2 ? styles.active : ""}`}
          >
            <h1 onClick={() => toogleFaq(2)}>
              How does the mint process work?
            </h1>
            <button type="button" onClick={() => toogleFaq(2)}>
              {faqOpen === 2 ? "-" : "+"}
            </button>
            <p>
              This drop will work via Metamask. Please have your Metamask wallet
              ready with ETH ready to purchase the amount of NFTs you would like
              to mint.
            </p>
          </div>
          <div
            className={`${styles.item} ${faqOpen === 3 ? styles.active : ""}`}
          >
            <h1 onClick={() => toogleFaq(3)}>How does the VIP Mint work?</h1>
            <button type="button">{faqOpen === 3 ? "-" : "+"}</button>
            <p>
              The 48 hour VIP Mint takes place on 11/9/22 and 11/10/22. During
              the VIP Mint, LCCC members and allowlist members can mint up to 2
              packs (8 NFTs!) for free!. Despite being a VIP member, you can not
              reveal what&apos;s in your pack until 11/11/2022 at 2pm ET. Be the
              light{" "}
              <a
                href="https://opensea.io/collection/lightcultcryptoclub"
                target="_blank"
                rel="noreferrer"
              >
                HERE
              </a>{" "}
              and your VIP spot is guaranteed.
            </p>
          </div>
          <div
            className={`${styles.item} ${faqOpen === 4 ? styles.active : ""}`}
          >
            <h1 onClick={() => toogleFaq(4)}>What is the mint price?</h1>
            <button type="button" onClick={() => toogleFaq(4)}>
              {faqOpen === 4 ? "-" : "+"}
            </button>
            <p>
              VIP Mint = Free (max 2/wallet)
              <br />
              Public = 0.06 ETH
            </p>
          </div>
          <div
            className={`${styles.item} ${faqOpen === 5 ? styles.active : ""}`}
          >
            <h1 onClick={() => toogleFaq(5)}>
              How many characters come in a pack?
            </h1>
            <button type="button" onClick={() => toogleFaq(5)}>
              {faqOpen === 5 ? "-" : "+"}
            </button>
            <p>4 individual alien characters. 4 NFTs for the price of one!</p>
          </div>
          <div
            className={`${styles.item} ${faqOpen === 6 ? styles.active : ""}`}
          >
            <h1>When is the reveal?</h1>
            <button type="button" onClick={() => toogleFaq(6)}>
              {faqOpen === 6 ? "-" : "+"}
            </button>
            <p>
              The reveal for both VIP members and public sale members will be on
              11/11/2022 at 2pm ET.
            </p>
          </div>
        </div>
        <Icon i="footer" className={styles.footer} />
      </section>
    </div>
  );
};

export default Landing;

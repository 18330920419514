import { fetchApi } from "utils";

export async function cardMetadata(uri) {
  const data = await fetchApi(`${uri}`, "get");
  return {
    name: data.name ?? "",
    image: data.image,
    rarity:
      data.attributes?.find((attr) => attr.trait_type === "Rarity")?.value ??
      "Common",
    number:
      data.attributes?.find((attr) => attr.trait_type === "Card #")?.value ??
      "",
  };
}

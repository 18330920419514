import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useCardContractRead } from "hooks";
import { cardMetadata } from "services/cardMetadata";
import CardLoadingImage from "assets/card-loading.png";
import styles from "./Card.module.scss";

const CARDS_CONTRACT_ADDRESS = process.env.REACT_APP_CARDS_CONTRACT_ADDRESS;
const OPENSEA_NETWORK =
  process.env.REACT_APP_REACT_ENV !== "prod" ? "goerli" : "ethereum";
const OPENSEA_SUBDOMAIN =
  process.env.REACT_APP_REACT_ENV !== "prod" ? "testnets." : "";
const OPENSEA_BASE_URL = `https://${OPENSEA_SUBDOMAIN}opensea.io/assets/${OPENSEA_NETWORK}/${CARDS_CONTRACT_ADDRESS}`;
const RETRY_TIMEOUT = 240000;

export const Card = ({ id, walletId }) => {
  const [loading, setLoading] = useState(true);
  const [card, setCard] = useState({});

  const { tokenOfOwnerByIndexData } = useCardContractRead(
    "tokenOfOwnerByIndex",
    [walletId, id]
  );
  const { tokenURIData, tokenURISuccess } = useCardContractRead("tokenURI", [
    tokenOfOwnerByIndexData?.toNumber(),
  ]);

  useEffect(() => {
    if (tokenURISuccess && tokenURIData) {
      cardMetadata(tokenURIData).then((data) => {
        setCard(data);
        setLoading(false);
        if (!data.number) {
          const retry = setTimeout(() => {
            cardMetadata(`${tokenURIData}?t=${Date.now()}`).then((data) => {
              setCard(data);
              clearTimeout(retry);
            });
          }, RETRY_TIMEOUT);
        }
      });
    }
  }, [tokenURIData, tokenURISuccess]);

  return (
    <div className={styles.card} style={{ opacity: loading ? 0.6 : 1 }}>
      <div className={styles.imageContainer}>
        <img src={loading ? CardLoadingImage : card.image} alt="card" />
      </div>
      <div className={styles.caption}>
        <span>{loading ? "Loading" : card.rarity}</span>
        <h4>{loading ? "Loading..." : card.name}</h4>
        <a
          href={`${OPENSEA_BASE_URL}/${tokenOfOwnerByIndexData?.toNumber()}`}
          target="_blank"
          rel="noreferrer"
          className="btn"
        >
          View on opensea
        </a>
      </div>
    </div>
  );
};

Card.propTypes = {
  id: PropTypes.number,
  walletId: PropTypes.string,
};

export default Card;
